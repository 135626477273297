<template>
  <screen_width_cont>
    <div class="cont_300N">
      <div class="modalCotizadorEtapas">
        <div class="modalCotizadorEtapasCard">
          <div class="modalCotizadorEtapasLogo">
            <img :src="logo" alt="" srcset="" />
          </div>

          <div class="modalCotizadorEtapasTitle title title_margin_top">
            {{ t("app.cotizadorModoPago.tit") }}
          </div>

          <div class="modalCotizadorEtapasText1 text_basico f_w_500">
            {{ t("app.cotizadorModoPago.sub_tit_1") }}
          </div>

          <div class="modalCotizadorEtapasText2">
            {{ t("app.cotizadorModoPago.text_1") }}
          </div>

          <div class="modalCotizadorEtapasText2">
            {{ t("app.cotizadorModoPago.text_2") }}
          </div>

          <div class="modalCotizadorEtapasBtn">
            <btnAction
              :textBtn="t('app.cotizadorModoPago.pagaFintoc')"
              @action="continuarFintoc"
              colorBack="azul"
              colorText="blco"
              :estadoBtn="estadoBtn"
            />

            <btnAction
              class="modalCotizadorEtapasBtn_btn_inf"
              :textBtn="t('app.cotizadorModoPago.pagaManual')"
              @action="continuar"
              colorBack="azul"
              colorText="blco"
              :estadoBtn="estadoBtn"
            />
          </div>

        </div>
      </div>
    </div>
  </screen_width_cont>
</template>


<script>
// import { IonContent, IonPage,  } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import btnAction from "../components/generales/btnAction.vue";
// import pasosGiro from "../components/generales/pasosProceso.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { getFintoc } from "@fintoc/fintoc-js";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "cotizadorModoPago",
  components: {
    btnAction,
    screen_width_cont,
  },
  data() {
    return {
      logo: require("../assets/imgs/logo.svg"),
      iconCotiEtapasUno: require("../assets/imgs/iconCotiEtapasUno.svg"),
      iconCotiEtapasDos: require("../assets/imgs/iconCotiEtapasDos.svg"),
      iconCotiEtapasTres: require("../assets/imgs/iconCotiEtapasTres.svg"),
    };
  },
  computed: {
    ...mapState([
      "remitente",
      "transaccion",
      "fintocPaymentIntent",
      "widthScreen",
    ]),
    destinatarioNombre() {
      let aux = "";
      if (this.remitente && this.transaccion && this.transaccion.id_persona) {
        aux = `${
          this.remitente.destinatarios[this.transaccion.id_persona].nombres
        } ${
          this.remitente.destinatarios[this.transaccion.id_persona].apellidos
        }`;
      }
      return aux;
    },
    estadoBtn() {
      let aux = "active";
      return aux;
    },
  },
  methods: {
    ...mapMutations(["setshowSpinner"]),
    ...mapActions(["callFintoc", "createBankTransfer"]),

    continuar() {
      this.$router.push({ name: "cotizadorEtapas", replace: true });
    },
    continuarFintoc() {
      this.setshowSpinner(true);
      this.createBankTransfer({
        amount: this.transaccion.monto_pagar,
      });
    },
    async openFintocWidget(token) {
      console.log(token);
      console.log("???");
      const Fintoc = await getFintoc();
      console.log("ok");
      const widget = Fintoc.create({
        holderType: "individual",
        widgetToken: token,
        product: "payments",
        publicKey: "pk_test_w9fMdzD3DhmcHMH8k3jURppySo8JRLHp",
        username: "40427672-7",
        onSuccess: () => {
          console.log("pago terminado");
          this.$router.push({ name: "cotizadorFinalizar", replace: true });
        },
        onExit: (data) => {
          console.log(data);
        },
        onEvent: (eventName, metadata) => {
          console.log(eventName, metadata);
        },
      });
      this.setshowSpinner(false);
      widget.open();
    },
  },
  watch: {
    fintocPaymentIntent() {
      if (this.fintocPaymentIntent.status) {
        if (this.fintocPaymentIntent.resp) {
          this.openFintocWidget(this.fintocPaymentIntent.widget_token);
        }
      }
    },
  },
  mounted() {},
};
</script>

<style>
.modalCotizadorEtapas {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  /* height: 100vh; */
}
.modalCotizadorEtapasCard {
  background: #ffffff;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1.5rem 1.125rem 2.5rem 1.125rem;
}
.modalCotizadorEtapasLogo {
  text-align: center;
}
.modalCotizadorEtapasLogo img {
  width: 9.6rem;
}
.modalCotizadorEtapasTitle {
  text-align: center;
  color: #801b1c;
}
.modalCotizadorEtapasText1 {
  margin-top: 1.4375rem;
  padding-bottom: 1.8rem;
  text-align: center;
  color: #787878;
}
.modalCotizadorEtapasText2 {
  margin-top: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #787878;
}
.modalCotizadorEtapasItems {
  margin-top: 1.875rem;
}
.modalCotizadorEtapasItem {
  margin-top: 1.75rem;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #787878;
}
.modalCotizadorEtapasItemImg {
  min-width: 1.375rem;
  min-height: 1.375rem;
  max-width: 1.375rem;
  max-height: 1.375rem;
}
.modalCotizadorEtapasItemImg img {
  width: 100%;
}
.modalCotizadorEtapasItemText {
  padding-left: 1rem;
}
.modalCotizadorEtapasItemText span {
  font-weight: 700;
  color: #4c4c4c;
}
.modalCotizadorEtapasBtn {
  margin-top: 3.625rem;
}
.modalCotizadorEtapasBtn_btn_inf {
  margin-top: 2rem;
}
</style>